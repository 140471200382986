

//// Denne filen skal inkludere de bibliotekene og scriptene som skal inkluderes over alt



import $ from "jquery";
window.$ = $;
window.jQuery = $;
import "popper.js";
import "bootstrap/dist/js/bootstrap.min";
import "datatables";

//import "./kendo/2019.1.220/kendo.all.min.js";
//import "./kendo/2019.1.220/kendo.aspnetmvc.min.js";
//import "~/Scripts/kendo/cultures/kendo.culture.nb-NO.min.js";






